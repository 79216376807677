export const mainMenu = [
  { label: "Home", path: "/" },
  { label: "About", path: "/about" },
  {
    label: "Services",
    path: "/services",
    submenu: [
      { label: "Facilitation", path: "/facilitation" },
      { label: "Retreats", path: "/retreats" },
      { label: "Leadership", path: "/leadership" },
      { label: "Coaching", path: "/coaching" },
    ],
  },
  { label: "Contact", path: "/contact" },
]

export const serviceMenu = [
  { label: "Facilitation", path: "/facilitation" },
  { label: "Retreats", path: "/retreats" },
  { label: "Leadership & Training", path: "/leadership" },
  { label: "Coaching", path: "/coaching" },
]

export const flatMenu = [
  { label: "Home", path: "/" },
  { label: "About", path: "/about" },
  { label: "Services", path: "/services" },
  { label: "Facilitation", path: "/facilitation" },
  { label: "Retreats", path: "/retreats" },
  { label: "Leadership", path: "/leadership" },
  { label: "Coaching", path: "/coaching" },
  { label: "Contact", path: "/contact" },
]

export const mobileMenu = [
  { label: "Home", path: "/" },
  { label: "About", path: "/about" },
  { label: "Services", path: "/services" },
  { label: "Contact", path: "/contact" },
]

export const footerMenu = [
  { label: "Home", path: "/" },
  { label: "About", path: "/about" },
  { label: "Facilitation", path: "/facilitation" },
  { label: "Retreats", path: "/retreats" },
  { label: "Leadership & Training", path: "/leadership" },
  { label: "Coaching", path: "/coaching" },
  { label: "Contact", path: "/contact" },
]
