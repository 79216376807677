export default {
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#73b0ea",
    secondary: "#2b547b",
    accent: "#73b0ea",
    muted: "#f9f9f9",
    border: "#e6e6e6",
    border_dark: "#22292f",
    dropdown: "#1a1a1a",
    bg_header: "#000",
    bg_topbar: "#73b0e9",
    bg_mobileMenu: "#73b0e9",
    bg_footer: "#191919",
  },
  fonts: {
    body: "Manrope, sans-serif",
    heading: "Manrope, sans-serif",
    monospace: "Menlo, monospace",
  },
  shadows: {
    header: "0 0 30px 0 rgba(0,0,0,.15)",
    tabbar: null,
  },
  fontSizes: [14, 16, 18, 20, 24, 32, 48, 64],
  styles: {
    root: {
      fontFamily: "body",
      "h1, h2, h3, h4, h5, h6": {
        fontWeight: 800,
      },
    },
  },
  breakpoints: ["750px", "960px", "1120px"],
  // EUI Variants
  pageBody: {
    maxWidth: 800,
    py: [30, 80],
    px: 20,
    opacity: 0,
    animation: "fadeInUp ease .4s .5s forwards",
    h2: { fontSize: [24, 42], mb: [40, 70] },
    figure: { m: 0 },
    p: {
      variant: "paragraph",
    },
    h3: {
      fontSize: [18, 23, 32],
      mb: 30,
      mt: [30, 80],
    },
  },
  paragraph: {
    mt: 0,
    fontSize: [16, 18, 21],
    lineHeight: 1.75,
    wordSpacing: [0, "3px"],
    mb: [20, 40],
  },
  homeText: {
    fontSize: [16, 18, 20],
    lineHeight: [1.8, 2],
    wordSpacing: [0, "4px"],
  },
  serviceTile: {
    minHeight: 300,
    padding: "5% 8% 8%",
    h3: {
      fontSize: [32, 42],
      mb: 0,
    },
    p: {
      fontSize: [16, 20],
      maxWidth: 700,
    },
    ".icon-wrapper": {
      border: "3px solid",
      p: "15px",
      display: "inline-flex",
      borderRadius: "50%",
      mb: 0,
    },
    svg: {
      width: [30, 45],
      height: [30, 45],
    },
    a: {
      fontFamily: "heading",
      border: "2px solid",
      p: "15px 30px",
      fontSize: ["16px", "18px"],
      fontWeight: "bold",
      display: "inline-block",
      mt: 20,
      transition: "all ease 0.3s",

      "&:hover": {
        transform: "translateY(-5px)",
      },
    },
  },
  header: {
    py: 15,
    px: [20, 30],
    fontFamily: "heading",
    fontWeight: 500,
    menu: {
      px: [null, 20, 60],
      a: {
        position: "relative",
        fontSize: 2,
        display: "block",
        color: "background",
        textDecoration: "none",
        py: 3,
        px: [null, 3, 4],
        transition: "all ease .3s",
        "&:hover": {
          color: "primary",
        },
      },
    },
    submenu: {
      bg: "dropdown",
      px: 2,
      boxShadow:
        "rgba(46, 41, 51, 0.08) 0px 4px 16px, rgba(71, 63, 79, 0.16) 0px 8px 24px",
      a: {
        px: 40,
        transition: "all ease .3s",
        "&:hover": {
          bg: "bg_header",
          color: "primary",
        },
      },
      "a:not(last-of-type)": {
        borderBottom: (t) => `1px solid ${t.colors.border_dark}`,
      },
    },
    menuButton: {
      fill: "background",
    },
  },
  carousel: {
    width: ["100%", "70vw"],
    minHeight: 400,
    height: "100%",
    overflow: "hidden",
    ".carousel-nav": {
      bottom: [0, "initial"],
      top: ["initial", "50%"],
    },
  },
}
