import React from "react"
import { Box, Flex } from "theme-ui"

const LinkedIn = () => (
  <Box as="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
  </Box>
)

const Mail = () => (
  <Box as="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z" />
  </Box>
)

const links = [
  {
    id: 1,
    url: "https://www.linkedin.com/in/alison-bluestone-b8411013",
    icon: <LinkedIn />,
  },
  { id: 2, url: "mailto:alison@momentousmeetings.com", icon: <Mail /> },
]

export default () =>
  links.map(({ id, url, icon }) => (
    <Flex
      key={id}
      as="a"
      sx={{
        border: "1px solid #404040",
        borderRadius: 2,
        p: 10,
        mx: "5px",
        svg: {
          width: 16,
          height: 16,
          fill: "#fff",
          transition: "fill ease .3s",
        },
        "&:hover": { svg: { fill: "#73b0ea" } },
      }}
      href={url}
      target={id === 1 ? "_blank" : undefined}
      rel={id === 1 ? "noopener noreferrer" : undefined}
    >
      {icon}
    </Flex>
  ))
