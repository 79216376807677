import React from "react"
import { Box } from "theme-ui"
import { Link } from "gatsby"

import Logo from "./LogoMinimal"
import { flatMenu } from "../config/menus"

const MobileNav = () => {
  return (
    <Box>
      <Box sx={{ textAlign: "center", p: "50px 20px" }}>
        <Logo original sx={{ height: 100 }} />
      </Box>
      <Box
        as="ul"
        sx={{
          listStyle: "none",
          lineHeight: 2,
          fontSize: 24,
          textAlign: "center",
          p: 0,
          a: {
            color: "#fff",
          },
        }}
      >
        {flatMenu.map(({ label, path }) => (
          <li key={path}>
            <Link to={path}>{label}</Link>
          </li>
        ))}
      </Box>
    </Box>
  )
}

export default MobileNav
