/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import { flatMenu } from "../config/menus"

export default ({ location }) => {
  const currentIndex = flatMenu.findIndex(({ path }) => path === location)
  const nextIndex = currentIndex === flatMenu.length - 1 ? 0 : currentIndex + 1

  return (
    <Pagination
      sx={{
        span: { color: "#000" },
        a: { fontFamily: "heading", color: "primary" },
      }}
    >
      <div
        sx={{
          maxWidth: 1080,
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          m: "auto",
        }}
      >
        <div className="page-container">
          {currentIndex !== 0 ? (
            <div className="pagination-link">
              <span>previous page:</span>
              <Link to={flatMenu[currentIndex - 1].path}>
                {flatMenu[currentIndex - 1].label}
              </Link>
            </div>
          ) : null}
          <div className="pagination-link">
            <span>next page:</span>
            <Link to={flatMenu[nextIndex].path}>
              {flatMenu[nextIndex].label}
            </Link>
          </div>
        </div>
      </div>
    </Pagination>
  )
}

const Pagination = styled.div`
  background: #efefef;
  padding: 50px 20px;
  display: flex;
  justify-content: flex-end;

  .page-container {
    display: flex;
    flex-direction: column;
  }

  .pagination-link {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  span {
    font-weight: bold;
    font-size: 20px;
  }

  a {
    font-size: 30px;
    font-weight: bold;
  }
`
