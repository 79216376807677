import React from "react"
import {
  Layout,
  Topbar,
  Header,
  Navbar,
  MobileMenu,
  Content,
  Main,
} from "elements-ui"
import { SEOProvider } from "@maker-ui/seo"
import { Global } from "@emotion/core"

import theme from "../config/theme"
import options from "../config/options"
import { mainMenu, flatMenu } from "../config/menus"
import globalStyles from "../config/global"
import Logo from "./Logo"
import HeaderWidgets from "./HeaderWidgets"
import MobileNav from "./MobileNav"
import Pagination from "./Pagination"
import Footer from "./Footer"

import logo from "../assets/favicon.png"

const seo = {
  title: "Momentous Meetings",
  description:
    "Momentous Meetings is a consulting and corporate coaching agency that helps its clients reach their optimal business potential.",
  twitter: "momentousmeetings",
  titleTemplate: " | Momentous Meetings",
  image: logo,
  siteUrl: "https://momentousmeetings.com",
}

export default ({ children, location }) => {
  return (
    <SEOProvider base={seo}>
      <Layout theme={theme} options={options}>
        <Global styles={globalStyles} />
        {location.pathname === "/" && (
          <Topbar
            sx={{
              color: "#fff",
              p: "15px",
              flexDirection: "column",
              "> div": {
                fontFamily: "heading",
              },
            }}
          >
            <div style={{ marginBottom: 10 }}>
              We hope you are staying healthy and positive in light of this new
              “world order”.
            </div>
            <div>
              <i style={{ marginRight: 15 }}>
                The secret of change is to focus all your energy not on fighting
                the old, but on building the new.
              </i>
              -Socrates
            </div>
          </Topbar>
        )}

        <Header>
          <Navbar
            logo={<Logo />}
            menu={mainMenu}
            pathname={location.pathname}
            widgetArea={<HeaderWidgets />}
          />
          <MobileMenu pathname={location.pathname}>
            <MobileNav />
          </MobileMenu>
        </Header>
        <Content>
          <Main>{children}</Main>
          {flatMenu.find(({ path }) => location.pathname === path) ? (
            <Pagination location={location.pathname} />
          ) : null}
        </Content>
        <Footer />
      </Layout>
    </SEOProvider>
  )
}
