export default {
  navigation: "basic",
  layout: "full-width",
  header: {
    maxWidth: "100%",
    sticky: false,
    stickyMobile: false,
    stickyScroll: false,
    hideWidgetsOnMobile: false,
    dropdown: {
      caret: true,
      transition: "scale",
    },
    breakIndex: 0,
  },
  mobileMenu: {
    width: "70vw",
    transition: "slideLeft",
    closeOnRouteChange: true,
  },
  content: {
    maxWidth: "100%",
    maxWidthSection: 1120,
    breakIndex: 0,
  },
}
