import React from "react"
import { Box } from "theme-ui"

export default props => (
  <Box
    as="svg"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1120 266"
    sx={{ height: [36, 48], px: [0, "10px"] }}
  >
    <path fill="#252525" d="M0 0h266v266H0z" />
    <path
      fill="#6fa6dd"
      d="M340.67 101.62c.85-3.88 2.18-13.09 3.39-21.94L352.79 17h2.55l31.4 69.23L418.5 17h2.79l7 65.22a192 192 0 0 0 2.91 19.4h-11.48c0-3.88-.37-8.36-1.1-16.49l-4.12-44.37-27.15 62h-2.43l-26.8-61.83-5.33 43.28c-.85 7.16-1.45 12.86-1.57 17.46z"
    />
    <path
      fill="#fff"
      d="M491.61 17.12A43 43 0 1 1 448.69 60a42.77 42.77 0 0 1 42.92-42.88zm0 77.47c18.19 0 32.86-15.4 32.86-34.55S509.8 25.6 491.61 25.6 458.75 41 458.75 60s14.67 34.59 32.86 34.59zM551.74 101.62c.85-3.88 2.19-13.09 3.4-21.94L563.87 17h2.55l31.4 69.23L629.58 17h2.79l7 65.22a189.17 189.17 0 0 0 2.91 19.4h-11.49c0-3.88-.36-8.36-1.09-16.49l-4.12-44.37-27.16 62H596l-26.8-61.88-5.33 43.28c-.85 7.16-1.46 12.86-1.58 17.46zM674.44 93.38h10.67c8.36 0 16.85-.12 20.49-.36v9c-3.64-.25-12.13-.37-20.49-.37h-21c.25-6.67.49-16.24.49-21.7v-39.8c0-8.36-.24-17.94-.49-21.58h20c8.36 0 16.73-.24 20.49-.48v9.09c-3.76-.36-12.13-.48-20.49-.48h-9.7v26.43H684c8.24 0 15.15-.12 18.91-.37v9c-3.76-.25-10.67-.37-18.91-.37h-9.58zM725.48 101.62c.25-3.39.49-11.15.49-17.58V40.15c0-7.27-.24-16.85-.49-23.4h2.31c3.64 4.49 5.82 6.91 9.94 11.52l44.86 53.35V40.27c0-6.91-.24-16.61-.37-21.7h10.19c-.24 5.34-.37 15-.37 21.7V85.5c0 5.09 0 10.55.37 17.58h-2.18c-3-3.88-5.09-6.31-9-10.79L735.55 38l-.24 46.19c0 6.31.24 14.07.48 17.46zM857 18.57c8.37 0 17.22-.12 21-.36v9c-3.76-.24-12.61-.48-21-.48h-8.49c-.12 4.48-.12 10.67-.12 15.64v37.55c0 8.25.12 18 .49 21.7h-10.76c.24-3.75.36-13.45.36-21.7V42.34c0-5-.12-11.16-.36-15.64h-8.37c-8.24 0-17 .24-20.73.48v-9c3.76.24 12.49.36 20.73.36zM928.81 17.12A43 43 0 1 1 885.89 60a42.77 42.77 0 0 1 42.92-42.88zm0 77.47c18.18 0 32.85-15.4 32.85-34.55S947 25.6 928.81 25.6 896 41 896 60s14.62 34.59 32.81 34.59zM1004.1 18.57c-.25 3.28-.49 13.1-.49 21.7v28c0 15.64 9.09 26.07 21.94 26.07s21.83-10.43 21.83-26.07v-28c0-6.91 0-16.61-.36-21.7h10.66c-.24 5.34-.36 15-.36 21.7V68c0 21.58-13.22 34.92-31.77 34.92S993.79 89.74 993.79 68V40.27c0-8.6-.24-18.42-.49-21.7zM1120 79.8c0 13.94-10.18 23.64-25.1 23.64-7.88 0-12.61-2.06-18.91-3V86.59c3 5.09 10.91 9 19.16 9 9 0 14.3-6.06 14.3-14.55 0-17.94-30.91-17.82-30.91-41.83 0-14.18 10.06-22.3 24-22.3 6.31 0 10.19 1.69 15.28 2.3v13.06c-2.18-5.33-8.25-7.76-15.28-7.76-8.12 0-13.94 4.85-13.94 13.46 0 17.46 31.4 17.82 31.4 41.83z"
    />
    <path
      fill="#6fa6dd"
      d="M340.67 247.11c.85-3.88 2.18-13.09 3.39-21.94l8.73-62.68h2.55l31.4 69.23 31.76-69.23h2.79l7 65.22a192 192 0 0 0 2.91 19.4h-11.48c0-3.88-.37-8.36-1.1-16.48l-4.12-44.38-27.15 61.95h-2.43l-26.8-61.83-5.33 43.28c-.85 7.16-1.45 12.86-1.57 17.46z"
    />
    <path
      fill="#fff"
      d="M463.36 238.87H474c8.37 0 16.85-.12 20.49-.36v9c-3.64-.24-12.12-.37-20.49-.37h-21c.24-6.66.48-16.24.48-21.7v-39.8c0-8.36-.24-17.94-.48-21.58h20c8.37 0 16.73-.24 20.49-.48v9.09c-3.76-.36-12.12-.48-20.49-.48h-9.7v26.43h9.58c8.24 0 15.16-.12 18.91-.37v9c-3.75-.25-10.67-.37-18.91-.37h-9.58zM524.71 238.87h10.67c8.37 0 16.85-.12 20.49-.36v9c-3.64-.24-12.12-.37-20.49-.37h-21c.24-6.66.48-16.24.48-21.7v-39.8c0-8.36-.24-17.94-.48-21.58h20c8.37 0 16.73-.24 20.49-.48v9.09c-3.76-.36-12.12-.48-20.49-.48h-9.7v26.43h9.58c8.24 0 15.16-.12 18.91-.37v9c-3.75-.25-10.67-.37-18.91-.37h-9.58zM618.07 164.06c8.36 0 17.21-.12 21-.36v9c-3.76-.24-12.61-.48-21-.48h-8.49c-.12 4.48-.12 10.67-.12 15.64v37.58c0 8.25.12 18 .49 21.7h-10.8c.25-3.75.37-13.45.37-21.7v-37.61c0-5-.12-11.16-.37-15.64h-8.36c-8.25 0-17 .24-20.73.48v-9c3.75.24 12.48.36 20.73.36zM665.72 225.41c0 8.25.24 18 .48 21.7h-10.79c.24-3.75.49-13.45.49-21.7v-39.64c0-8.25-.25-18.07-.49-21.71h10.79c-.24 3.64-.48 13.46-.48 21.71zM692 247.11c.25-3.39.49-11.15.49-17.58v-43.89c0-7.27-.24-16.85-.49-23.4h2.31c3.64 4.49 5.82 6.92 9.94 11.52l44.86 53.35v-41.34c0-6.92-.24-16.61-.36-21.71H759c-.24 5.34-.36 15-.36 21.71V231c0 5.09 0 10.55.36 17.58h-2.18c-3-3.88-5.09-6.31-9-10.79l-45.71-54.32-.24 46.19c0 6.31.24 14.07.48 17.46zM847.82 244.45c-7.4 2.42-12.61 4.12-22.67 4.12-26.19 0-44.62-19.28-44.62-43s18.19-43 44.37-43a56.65 56.65 0 0 1 21.34 4v11.4c-5.94-5-12.73-6.67-21-6.67-20.25 0-34.68 15.27-34.68 34.31S805.38 240 825.15 240c5.45 0 10.42-.73 13.21-2.42v-13.7c0-8.49-.24-11.64-.48-15.28h10.42c-.24 3.64-.48 7-.48 15.28zM911.83 225.29c0 13.94-10.18 23.64-25.09 23.64-7.89 0-12.61-2.06-18.92-3v-13.85c3 5.09 10.91 9 19.16 9 9 0 14.3-6.06 14.3-14.55 0-17.94-30.91-17.82-30.91-41.83 0-14.18 10.06-22.3 24-22.3 6.31 0 10.19 1.69 15.28 2.3v13.09c-2.18-5.33-8.24-7.76-15.28-7.76-8.12 0-13.94 4.85-13.94 13.46 0 17.43 31.4 17.79 31.4 41.8z"
    />
    <path
      d="M57.9 113.14L6.58 0H0v95.63l7.65-62.11L54.7 142.09H59L106.65 33.3l7.23 77.92c1.28 14.26 1.92 22.14 1.92 28.95H136c-2.12-10-3.4-18.94-5.11-34.06L119.48 0h-9.67z"
      style={{ fill: "#85b2e6" }}
    />
    <path
      d="M266 264.19c-2-9.83-3.31-19.13-5-34.15L247.85 108h-5.22L183.2 237.52 124.45 108h-4.77l-16.33 117.27c-2.23 16.32-4.69 33.29-6.28 40.73h19.67c.25-8.56 1.38-19.12 2.94-32.33l10-81L178.77 266h6.6l49.77-113.54 7.72 83c1.34 15 2 23.34 2 30.52H266z"
      style={{ fill: "#fff" }}
    />
  </Box>
)
