import React from "react"
import { Box } from "theme-ui"

export default ({ original = false, sx, props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 266 266"
    sx={sx}
    {...props}
  >
    <path fill={original ? "#242424" : "#fff"} d="M0 0h266v266H0z" />
    <path
      d="M57.9 113.14L6.58 0H0v95.63l7.65-62.11L54.7 142.09H59L106.65 33.3l7.23 77.92c1.28 14.26 1.92 22.14 1.92 28.95H136c-2.12-10-3.4-18.94-5.11-34.06L119.48 0h-9.67z"
      fill="#85b2e6"
    />
    <path
      fill={original ? "#fff" : "#000"}
      d="M266 264.19c-2-9.83-3.31-19.13-5-34.15L247.85 108h-5.22L183.2 237.52 124.45 108h-4.77l-16.33 117.27c-2.23 16.32-4.69 33.29-6.28 40.73h19.67c.25-8.56 1.38-19.12 2.94-32.33l10-81L178.77 266h6.6l49.77-113.54 7.72 83c1.34 15 2 23.34 2 30.52H266z"
    />
  </Box>
)
