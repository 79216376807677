/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"
import { Footer } from "elements-ui"
import { Link } from "gatsby"

import { LinkedInIcon, EmailIcon } from "../assets/icons"
import { footerMenu } from "../config/menus"

const socialLinks = [
  {
    icon: <LinkedInIcon />,
    path: "https://www.linkedin.com/in/alison-bluestone-b8411013",
  },
  { icon: <EmailIcon />, path: "mailto:alison@momentousmeetings.com" },
]

export default () => (
  <Footer
    maxWidth="100%"
    border="none"
    sx={{
      p: "20px 0 0",
      position: "relative",
      "> div": { flexDirection: "column", justifyContent: "center" },
    }}
  >
    <ul
      sx={{
        lineHeight: 2,
        listStyle: "none",
        display: ["block", "flex"],
        columns: 2,
        justifyContent: "center",
        flexWrap: "wrap",
        p: "0 20px 20px",
      }}
    >
      {footerMenu.map(item => (
        <li key={item.path}>
          <Link
            to={item.path}
            sx={{
              color: "#ababab",
              px: [0, 20],
              transition: "all ease .3s",
              fontFamily: "heading",
              "&:hover": { color: "#fff" },
            }}
          >
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
    <Copyright
      sx={{
        bg: "#000",
        fontFamily: "heading",
        textAlign: ["center", "left"],
        div: {
          width: ["100%", "auto"],
        },
      }}
    >
      <div className="content-wrapper">
        <div sx={{ mb: [20, 0] }}>
          Copyright © {new Date().getFullYear()}{" "}
          <Link to="/">Momentous Meetings LLC</Link>
        </div>
        <SocialIcons>
          {socialLinks.map(link => (
            <a
              key={link.path}
              href={link.path}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.icon}
            </a>
          ))}
        </SocialIcons>
      </div>
    </Copyright>
  </Footer>
)

const Copyright = styled.div`
  // position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  color: #fff;
  font-size: 14px;

  a {
    color: #fff;
  }

  .content-wrapper {
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`

const SocialIcons = styled.div`
  svg {
    height: 18px;
    fill: #fff;
    margin: 0 10px;
  }
`
